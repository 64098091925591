import { useQuery } from "react-query";
import endpoints from "util/endpoints";
import http from "util/http";

export const getRequest = async (endpoint: string) => {
  try {
    const result = await http.get(endpoint);
    if (result?.data?.status === 200 || result?.data?.status === "200") {
      return result?.data?.data;
    } else {
      throw new Error();
    }
  } catch (error: any) {
    throw error;
  }
};

export const useMaintenanceData = () => {
  const customEndpoint = endpoints.HEALTH_CHECK;

  return useQuery(
    customEndpoint,
    async () => {
      const data = await getRequest(customEndpoint);

      // Store in localStorage as a fallback
      const termsAndCondition = data?.meta?.termsAndCondition ?? "";
      if (termsAndCondition) {
        localStorage.setItem("termsAndCondition", termsAndCondition);
      }

      return data;
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
    }
  );
};
