import ReactPixel from "react-facebook-pixel";

type TEventName =
  | "New User Signup"
  | "Website Visit"
  | "New Transaction"
  | "Pay for Transaction";

type TPayload = {
  [key: string]: any;
};

export const facebookEvent = (
  eventName: TEventName,
  payload: TPayload = {}
) => {
  // Do not submit event in development environment
  if (process.env.REACT_APP_ENV !== "production") {
    return;
  }
  ReactPixel.track(eventName, payload);
};
