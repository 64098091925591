import { CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Select,
} from "antd";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { UseMutateFunction } from "react-query";
import {
  getActivePayoutPartner,
  getBankById,
  useGetRemitBanks,
  useValidateBankAccountDetails,
} from "../RecipientsHelper";
import { BankAccountNameWrapper } from "../RecipientsStyles";

const { Option } = Select;

interface RecipientFormNGNProps {
  recipientCountry?: any;
  initialValues?: any;
  transferMethod?: string;
  form: FormInstance<any>;
  createRecipientMutate: UseMutateFunction<
    AxiosResponse<any> | undefined,
    unknown,
    any,
    unknown
  >;
  isSubmitting: boolean;
  handleCancel: () => void;
}

const RecipientFormNGN = ({
  recipientCountry,
  createRecipientMutate,
  initialValues,
  transferMethod,
  form,
  isSubmitting,
  handleCancel,
}: RecipientFormNGNProps) => {
  const payoutCountryCode = recipientCountry?.countryIso3 || "NGA";

  const { data: initialBankList, isLoading: isBankListLoading } =
    useGetRemitBanks(payoutCountryCode);

  const [bankList, setBankList] = useState<any[]>([]);
  const [bankId, setBankId] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [accountNameFound, setAccountNameFound] = useState<string>("");
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);

  useEffect(() => {
    if (initialBankList) {
      setBankList(initialBankList);
    }
  }, [initialBankList]);

  const onSearchBankList = (value: string) => {
    const lowerCaseSearchString = value.toLowerCase();

    const result = lowerCaseSearchString
      ? initialBankList?.filter((item: any) =>
          item?.bank.toLowerCase()?.includes(lowerCaseSearchString)
        )
      : initialBankList;

    setBankList(result);
  };

  const onChangeSelectedBank = (value: string) => {
    setBankList(initialBankList); //reset list
    setBankId(value);
  };

  const updateAccountNumberState = () => {
    const recipientAccountNumberFieldValue =
      form.getFieldValue("accountNumber");
    const recipientAccountNumberFieldError =
      form.getFieldError("accountNumber");

    // Determine if there are any errors
    const isAccountNumberFieldError =
      recipientAccountNumberFieldError.length > 0;
    const isAccountNumberFieldValidating =
      form.isFieldValidating("accountNumber");

    // Update the state based on error presence or validation status
    setAccountNumber(
      isAccountNumberFieldError || isAccountNumberFieldValidating
        ? ""
        : recipientAccountNumberFieldValue
    );
  };

  const clearAllStates = () => {
    setBankId("");
    setAccountNumber("");
  };

  //handle and organize values to be submitted
  const onFormFinish = (values: any) => {
    const [firstName, ...last] = accountNameFound.split(" ");
    const lastName = last.join(" ");

    let valuesTosubmit = {};

    if (accountNameFound !== "") {
      valuesTosubmit = {
        ...initialValues,
        ...values,
        firstName: firstName,
        lastName: lastName,
        countryCode: recipientCountry.countryCode,
        state: recipientCountry.name,
        remittanceHandler: getActivePayoutPartner(payoutCountryCode),
        confirmPhoneCode: "",
        transferMethod: transferMethod,
        bankName: getBankById(initialBankList, values?.bankId),
      };

      createRecipientMutate(valuesTosubmit);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="new_recipient_form"
      onFieldsChange={() => {
        updateAccountNumberState();
      }}
      onReset={clearAllStates}
      onFinish={onFormFinish}
    >
      <Form.Item
        name="bankId"
        label="Bank name"
        rules={[
          {
            required: true,
            message: "Please search or select bank name!",
          },
        ]}
      >
        <Select
          placeholder="Search or select bank name"
          size="large"
          showSearch
          onChange={onChangeSelectedBank}
          onSearch={onSearchBankList}
          filterOption={false}
          loading={isBankListLoading}
        >
          {bankList?.map((item, index) => (
            <Option key={item?.id + index} value={item?.id}>
              {item?.bank}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="accountNumber"
        label="Account number"
        validateDebounce={700}
        rules={[
          {
            max: 10,
            min: 10,
            required: true,
            message: "Account number must be 10 digits!",
          },
        ]}
      >
        <Input
          type="number"
          size="large"
          placeholder="Enter ten digit account number"
        />
      </Form.Item>
      <BankAccountName
        setIsFetching={setIsFetchingDetails}
        accountNumber={accountNumber}
        bankId={bankId}
        setAccountNameFound={setAccountNameFound}
      />

      <Divider />

      <Form.Item>
        <Flex justify="flex-end" gap={12}>
          <Button type="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isFetchingDetails}
          >
            Submit
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default RecipientFormNGN;

interface BankAccountNameProps {
  accountNumber: string;
  bankId: string;
  setAccountNameFound: React.Dispatch<React.SetStateAction<string>>;
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

const BankAccountName = ({
  accountNumber,
  bankId,
  setAccountNameFound,
  setIsFetching,
}: BankAccountNameProps) => {
  const {
    mutate: validateDetails,
    isLoading,
    isError,
    error,
  } = useValidateBankAccountDetails((res: any) => onSuccess(res));
  const err: any = error;

  const [accountName, setAccountName] = useState<string>("");

  useEffect(() => {
    if (isLoading) {
      setIsFetching(isLoading);
    } else if (isError || accountName === "Not found") {
      setIsFetching(true); // disable submit button when account not found
    } else {
      setIsFetching(false);
    }
  }, [isLoading, isError]);

  const isDetailsAvailable =
    accountNumber && accountNumber !== "" && bankId && bankId !== "";

  useEffect(() => {
    if (isDetailsAvailable) {
      const payload = {
        accountnumber: accountNumber,
        bank: bankId,
      };
      validateDetails(payload);
    }
  }, [accountNumber, bankId, validateDetails, isDetailsAvailable]);

  const onSuccess = (value: string) => {
    setAccountName(value);
    if (value !== "Not found") {
      setAccountNameFound(value);
    }
  };

  if (!isDetailsAvailable) {
    return null;
  }

  if (isLoading) {
    return (
      <BankAccountNameWrapper>
        <LoadingOutlined rev={undefined} />
        Verifying account details...
      </BankAccountNameWrapper>
    );
  }

  if (isError || accountName === "Not found") {
    const customErrorMsg =
      "Account not found. Please check the details and try again.";

    return (
      <Alert
        className="_alert"
        message={isError ? err?.message : customErrorMsg}
        type="error"
        showIcon
        banner
      />
    );
  }

  return (
    <BankAccountNameWrapper $isNameFound>
      <CheckCircleFilled rev={undefined} />
      {accountName}
    </BankAccountNameWrapper>
  );
};
