import { Alert, Divider, Form, Modal } from "antd";
import { useState } from "react";
import { ErrorMessages } from "../../utils/ReusablePageContent";
import {
  replaceUnderScore,
  transferMethodsInWords,
} from "../../utils/reuseableUtils";
import { userAppValues } from "../../utils/useAppValues";
import { useCreateRecipient } from "./RecipientsHelper";
import RecipientFormDefault from "./controls/RecipientFormDefault";
import RecipientFormNGN from "./controls/RecipientFormNGN";

interface NewRecipientProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  transferQuoteResponse: any;
  onProccedToReview: (id: string) => void;
}

export type ValuesToSubmit = {
  values: Record<string, any>;
  complete: boolean;
};

const initialValues = {
  firstName: "",
  lastName: "",
  mobile: "",
  phoneCode: "",
  confirmMobile: "",
  confirmPhoneCode: "CM21",
  email: "",
  state: "",
  reason: "",
  bankName: "",
  accountNumber: "",
  pickupPoint: "",
  branchCode: "",
  bankCode: "",
  key: "",
  countryCode: "CM21",
  accountBranch: "",
  recipientAccountNumber: "",
  mobileMoneyProvider: "",
  transferMethod: "",
};

export const NewRecipient = ({
  open,
  setOpen,
  transferQuoteResponse,
  onProccedToReview,
}: NewRecipientProps) => {
  const { PayoutCountries } = userAppValues();
  const { destinationCurrency } = transferQuoteResponse || {};

  const [valuesToSubmit, setValuesTosubmit] = useState<ValuesToSubmit>({
    values: {},
    complete: false,
  });

  const {
    mutate: createRecipientMutate,
    isLoading,
    isError,
    error,
  } = useCreateRecipient((id) => {
    handleCancel();
    onProccedToReview(id);
  });
  const err: any = error;

  const transferMethod =
    transferMethodsInWords[transferQuoteResponse?.transferMethod];

  const recipientCountry = PayoutCountries.find(
    (country: any) => country.currency === destinationCurrency
  );
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  const isNGNPayoutCurrency = destinationCurrency === "NGN";

  const submiitForm = () => {
    console.log("Values: ", valuesToSubmit.values);
    // createRecipientMutate(valuesToSubmit.values);
  };

  return (
    <Modal
      title={`Add a new recipient`}
      open={open}
      onCancel={handleCancel}
      width={600}
      destroyOnClose={true}
      confirmLoading={isLoading}
      onOk={() => {
        form.validateFields().then((values) => {
          if (valuesToSubmit.complete) {
            // submiitForm();
          }
        });
      }}
      footer={null}
    >
      <Divider style={{ marginTop: "12px" }} />
      <p>
        Payment method ({replaceUnderScore(transferMethod)?.toLocaleUpperCase()}
        )
      </p>
      <Alert
        description={
          <span>
            It is your <b style={{ color: "red" }}>RESPONSIBILTY</b> to ensure
            the payment details are correct.
          </span>
        }
        type="info"
        showIcon
        closable
      />
      <div style={{ marginTop: "32px", width: "100%" }}>
        {isNGNPayoutCurrency ? (
          <RecipientFormNGN
            recipientCountry={recipientCountry}
            createRecipientMutate={createRecipientMutate}
            initialValues={initialValues}
            transferMethod={transferMethod}
            isSubmitting={isLoading}
            handleCancel={handleCancel}
            form={form}
          />
        ) : (
          <RecipientFormDefault
            recipientCountry={recipientCountry}
            createRecipientMutate={createRecipientMutate}
            initialValues={initialValues}
            transferMethod={transferMethod}
            form={form}
            isSubmitting={isLoading}
            handleCancel={handleCancel}
          />
        )}
      </div>

      {isError && <ErrorMessages errorMessage={err?.message} />}
    </Modal>
  );
};
