import { queryClient } from "index";
import { useMutation, useQuery } from "react-query";
import { toastAction } from "redux/actions/actions";
import store from "redux/store";
import endpoints from "util/endpoints";
import { getRequest, postRequest } from "util/http";
import { parseEndpointParameters } from "../../../../../util/util";
import { getAppValueDataByName } from "../../utils/reuseableUtils";

export const useCreateRecipient = (onSuccess: (id: string) => void) => {
  const user = store.getState().auth.user;
  const transfer = store.getState().transfer;

  const userId = user?.id;

  return useMutation(
    (values: any) => {
      values = {
        firstName: values.firstName,
        lastName: values.lastName,
        profile: {
          ...values,
          remittanceHandler:
            values.remittanceHandler || transfer.remittanceHandler,
        },
      };
      return postRequest(
        { ...values },
        parseEndpointParameters(endpoints.CREATE_RECIPIENT, userId),
        "Failed to to create recipient, Pls try again"
      );
    },
    {
      onSuccess: (data) => {
        const recipientId = data?.data?.data?.id;
        toastAction({
          show: true,
          type: "success",
          message: "New recipient added",
        });
        queryClient.invalidateQueries(
          parseEndpointParameters(endpoints.RECIPIENTS, userId)
        );
        onSuccess(recipientId);
      },
    }
  );
};

export const useGetRemitBanks = (countryCode: string) => {
  const customEndpoint = parseEndpointParameters(
    endpoints.GET_REMIT_BANKS,
    countryCode
  );
  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch list of banks"),
    {
      onSuccess: (data) => {
        // Removed the filter for active payout
        // const formattedBanks = filterBanksWithPayout(
        //   data,
        //   getActivePayoutPartner(countryCode)
        // );

        return data;
      },
    }
  );
};

function filterBanksWithPayout(data: any[], payout: string): any[] {
  return data?.filter((item) => item.meta[payout] !== undefined);
}

export function getActivePayoutPartner(countryCode: string): string {
  const { values } = store.getState().appValues;
  const payoutSettings = getAppValueDataByName(values.data, "payout-settings");

  const payoutSettingsForCountryCode = payoutSettings?.[countryCode];

  const activePayout =
    payoutSettings && payoutSettingsForCountryCode
      ? JSON.parse(payoutSettingsForCountryCode)?.payout || {}
      : {};

  return typeof activePayout === "string" ? activePayout : "";
}

export const useValidateBankAccountDetails = (onSuccess: Function) => {
  return useMutation(
    (values: any) => {
      return postRequest(
        values,
        parseEndpointParameters(endpoints.REMIT_VALIDATE),
        "Account verification failed. Please check the details or try again later"
      );
    },
    {
      onSuccess: (data) => {
        const accountName = data?.data?.data?.AccountName || "";
        onSuccess(accountName === "" ? "Not found" : accountName);
      },
    }
  );
};

export const getBankById = (bankList: any, bankId: string): string => {
  const bankData = bankList?.find((item: any) => item.id === bankId);

  return bankData?.bank || "N/A";
};
