import {
  Alert,
  Button,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
  Typography,
} from "antd";
import { AxiosResponse } from "axios";
import {
  getFlagURL,
  mobileMoneyProviderList,
} from "components/pages/transcations-flow/utils/reuseableUtils";
import React, { useEffect, useState } from "react";
import { UseMutateFunction } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { TRANSFER } from "redux/actionTypes";

const { Text, Link } = Typography;
const { Option } = Select;

interface RecipientFormDefaultProps {
  recipientCountry: any;
  initialValues: any;
  transferMethod: string;
  form: FormInstance<any>;
  createRecipientMutate: UseMutateFunction<
    AxiosResponse<any> | undefined,
    unknown,
    any,
    unknown
  >;
  isSubmitting: boolean;
  handleCancel: () => void;
}

const RecipientFormDefault = ({
  recipientCountry,
  initialValues,
  transferMethod,
  form,
  isSubmitting,
  createRecipientMutate,
  handleCancel,
}: RecipientFormDefaultProps) => {
  const transfer = useSelector((state: any) => state.transfer);
  const [isPhoneNumberMatched, setIsPhoneNumberMatched] = useState(false);
  const [mobileMoneyProvider, setMobileMoneyProvider] = useState("");
  const [activeBankTransferTab, setActiveBankTransferTab] = useState("bank");
  const { recipientBankDeatails } = transfer || {};

  const handleOnSelcetMMPChange = (value: string) => {
    setMobileMoneyProvider(value);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleCopy = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const onBankTranferTabChange = (key: string) => {
    setActiveBankTransferTab(key);
  };

  const onFormFinish = (values: any) => {
    // Remove unnecessary fields
    delete values.recipientCountry;

    // Create a new object for initial values to avoid mutation
    const updatedInitialValues = {
      ...initialValues,
      phoneCode: recipientCountry?.dialCode || "",
      confirmPhoneCode: recipientCountry?.dialCode || "",
    };

    values.mobileMoneyProvider = mobileMoneyProvider || "";
    values.transferMethod = transferMethod;

    // Combine values
    const combinedValues = {
      ...updatedInitialValues,
      ...values,
      ...recipientBankDeatails,
      state: recipientCountry.name,
      countryCode: recipientCountry.countryCode,
    };

    // Logic for Mobile Money and Cash Pickup
    if (transferMethod === "mobile_money" || transferMethod === "cash_pickup") {
      createRecipientMutate(combinedValues);
    }

    // Logic for Bank Transfer
    if (transferMethod === "bank_transfer") {
      if (
        activeBankTransferTab === "bank" &&
        combinedValues.accountNumber !== ""
      ) {
        createRecipientMutate(combinedValues);
      } else if (
        activeBankTransferTab === "microfinance" &&
        combinedValues.recipientAccountNumber !== "" &&
        combinedValues.accountBranch !== ""
      ) {
        //reset bank deatils if available
        combinedValues.accountNumber = "";
        combinedValues.countryCode = "";
        combinedValues.bankCode = "";
        combinedValues.branchCode = "";
        combinedValues.key = "";

        //Mannually cheeck and set extra required values
        combinedValues.recipientAccountNumber = form.getFieldValue(
          "recipientAccountNumber"
        );
        combinedValues.accountBranch = form.getFieldValue("accountBranch");

        createRecipientMutate(combinedValues);
      }
    }
  };

  const countryFlag = (
    <Tooltip title={`${recipientCountry?.name}`}>
      <Space align="center">
        <img
          src={getFlagURL(recipientCountry?.countryCode || "")}
          alt={recipientCountry?.name}
          style={{
            width: "24px",
            height: "18px",
            marginTop: "5px",
          }}
        />
      </Space>
    </Tooltip>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      name="new_recipient_form"
      onFinish={onFormFinish}
      initialValues={initialValues}
    >
      <>
        <Form.Item
          name="firstName"
          label="First name"
          rules={[
            {
              required: true,
              message: "Please input first name!",
            },
          ]}
        >
          <Input size="large" placeholder="First name" />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last name"
          rules={[
            {
              required: true,
              message: "Please input last name!",
            },
          ]}
        >
          <Input size="large" placeholder="Last name" />
        </Form.Item>
      </>

      <Form.Item
        name="recipientCountry"
        label="Recipient Country"
        initialValue={recipientCountry?.countryCode}
        rules={[
          {
            required: true,
            message: "Please select recipient country",
          },
        ]}
      >
        <Select
          placeholder="Select recipient country"
          defaultValue={"CM"}
          size="large"
          allowClear
          //onChange={handleOnSelcetChange}
        >
          <Option value={recipientCountry?.countryCode}>
            {recipientCountry?.name}
          </Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="mobile"
        label="Phone number"
        validateDebounce={1500}
        rules={[
          {
            max: 9,
            min: 9,
            required: transferMethod === "mobile_money",
            message: "Phone number should be 9 digits!",
          },
        ]}
      >
        <Input
          addonBefore={countryFlag}
          size="large"
          type="numnber"
          placeholder="Phone number"
          onPaste={handlePaste}
          onCopy={handleCopy}
        />
      </Form.Item>

      {transferMethod === "mobile_money" && (
        <Form.Item
          name="confirmMobile"
          label="Confirm phone Number"
          validateDebounce={500}
          extra={
            isPhoneNumberMatched && <Text type="success">Numbers Matched!</Text>
          }
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (value !== form.getFieldValue("mobile")) {
                  setIsPhoneNumberMatched(false);
                  return Promise.reject("Numbers do not match, please update");
                } else {
                  setIsPhoneNumberMatched(true);
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            addonBefore={countryFlag}
            type="numnber"
            size="large"
            placeholder="Confirm phone number"
            onPaste={handlePaste}
            onCopy={handleCopy}
          />
        </Form.Item>
      )}

      {recipientCountry?.countryCode !== "CM" && (
        <Form.Item
          name="mobileMoneyProvider"
          label="Select Mobile Money Provider"
          initialValue={recipientCountry?.countryCode}
          rules={[
            {
              required: true,
              message: "Please Mobile Money Provider",
            },
          ]}
        >
          <Select
            placeholder="Select Mobile Money Provider"
            size="large"
            allowClear
            onChange={handleOnSelcetMMPChange}
          >
            {mobileMoneyProviderList[recipientCountry?.countryCode || ""]?.map(
              (item: any, index: number) => (
                <Option value={item.value} key={index + "CM"}>
                  {item.label}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
      )}

      {transferMethod === "cash_pickup" && CashPickupMethod}

      {transferMethod === "bank_transfer" && (
        <Tabs
          defaultActiveKey="bank"
          destroyInactiveTabPane
          items={items}
          onChange={onBankTranferTabChange}
        />
      )}

      <Divider />

      <Form.Item>
        <Flex justify="flex-end" gap={12}>
          <Button type="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Submit
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};

export default RecipientFormDefault;

const CashPickupMethod = (
  <>
    <Form.Item
      name="pickupPoint"
      label="Pickup point"
      initialValue="SB Capital (Akwa)"
      rules={[
        {
          required: true,
          message: "Please select pickup point!",
        },
      ]}
    >
      <Select
        placeholder="Select a pickup location"
        defaultValue={"SB Capital (Akwa)"}
        size="large"
        allowClear
        //onChange={handleOnSelcetChange}
      >
        <Option value="SB Capital (Akwa)">SB Capital (Akwa)</Option>
      </Select>
    </Form.Item>
  </>
);

const MobileMoneyMethod = <></>;

const Microfinance = (
  <>
    <Form.Item
      name="bankName"
      label="Micro Finance Name"
      rules={[
        {
          required: true,
          message: "Please enter bank name!",
        },
      ]}
    >
      <Input size="large" placeholder="Bank name" />
    </Form.Item>
    <Form.Item
      name="recipientAccountNumber"
      label="Recipient Account Number"
      rules={[
        {
          required: true,
          message: "Please enter recipient account number!",
        },
      ]}
    >
      <Input type="numnber" size="large" placeholder="Account Number" />
    </Form.Item>
    <Form.Item
      name="accountBranch"
      label="Account Branch"
      rules={[
        {
          required: true,
          message: "Please enter account branch!",
        },
      ]}
    >
      <Input type="numnber" size="large" placeholder="Account Branch" />
    </Form.Item>
  </>
);

const AccountInputField = () => {
  const dispatch = useDispatch();
  const transfer = useSelector((state: any) => state.transfer);
  const [bankCode, setBankCode] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [key, setKey] = useState("");
  const [err, setErr] = useState(false);

  const countryCode = "CM21";

  useEffect(() => {
    const bankCodeRegex = /^\d{5}$/; // /^(\s)*[0-9]{5}(\s)*$/
    const branchCodeRegex = /^\d{5}$/;
    const accountNoRegex = /^\d{11}$/;
    const keyRegex = /^\d{2}$/;

    const isValidBankCode = bankCodeRegex.test(bankCode);
    const isValidBranchCode = branchCodeRegex.test(branchCode);
    const isValidAccountNo = accountNoRegex.test(accountNo);
    const isValidKey = keyRegex.test(key);

    if (
      isValidBankCode &&
      isValidBranchCode &&
      isValidAccountNo &&
      isValidKey
    ) {
      dispatch({
        type: TRANSFER,
        payload: {
          ...transfer,
          recipientBankDeatails: {
            accountNumber: `${countryCode} ${bankCode} ${branchCode} ${accountNo} ${key}`,
            countryCode,
            bankCode,
            branchCode,
            key,
          },
        },
      });
      setErr(false);
    } else {
      setErr(true);
      dispatch({
        type: TRANSFER,
        payload: {
          ...transfer,
          recipientBankDeatails: {
            accountNumber: "",
            countryCode: "",
            bankCode: "",
            branchCode: "",
            key: "",
          },
        },
      });
    }
  }, [bankCode, branchCode, accountNo, key]);

  return (
    <Space direction="vertical">
      <Space wrap>
        <Input size="large" value="CM21" disabled />
        <Input
          size="large"
          value={bankCode}
          placeholder="Bank Code"
          onChange={(e) => setBankCode(e.target.value)}
        />
        <Input
          size="large"
          value={branchCode}
          placeholder="Bank Branch"
          onChange={(e) => setBranchCode(e.target.value)}
        />
        <Input
          size="large"
          value={accountNo}
          placeholder="Account No"
          onChange={(e) => setAccountNo(e.target.value)}
        />
        <Input
          size="large"
          value={key}
          placeholder="Key"
          onChange={(e) => setKey(e.target.value)}
        />
      </Space>
      {err && (
        <Alert
          showIcon
          message="Use format CM21 10005 00001 01234567890 12"
          type="error"
        />
      )}
    </Space>
  );
};

const BankTransfer = (
  <>
    <Form.Item
      name="bankName"
      label="Beneficiary Bank Name"
      rules={[
        {
          required: true,
          message: "Please enter bank name!",
        },
      ]}
    >
      <Input size="large" placeholder="Bank Name" />
    </Form.Item>
    <AccountInputField />
  </>
);

const items: TabsProps["items"] = [
  {
    key: "bank",
    label: "Bank",
    children: BankTransfer,
  },
  {
    key: "microfinance",
    label: "Microfinance",
    children: Microfinance,
  },
];
